<template>
  <div id="waterDailyMonitorRecord">
    <el-dialog
      :title="waterDailyMonitorRecordFormTitle"
      width="1200px"
      :visible.sync="waterDailyMonitorRecordDialogVisible"
      :close-on-click-modal="false"
      @close="waterDailyMonitorRecordDialogClose"
    >
      <div id="pdfDom">
        <el-form
          ref="waterDailyMonitorRecordFormRef"
          :model="waterDailyMonitorRecordForm"
          :rules="waterDailyMonitorRecordFormRules"
          label-position="left"
          label-width="120px"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="检测日期" prop="checkDate">
                <el-date-picker
                  v-model="waterDailyMonitorRecordForm.checkDate"
                  placeholder="请选择检测日期"
                  value-format="yyyy-MM-dd"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <section class="newRule" style="margin-bottom: 20px">
            <el-button
              v-if="handleType != 'detail' && handleType != 'review'"
              type="small"
              @click="addList()"
            >
              添加
            </el-button>
            <!-- <el-button type="small" @click="getList()">显示数据</el-button> -->
            <vxe-table
              border
              resizable
              show-overflow
              :span-method="rowspanMethod"
              :data="tableData"
            >
              <vxe-table-column field="idx" title="取水口">
                <template #default="{ row, rowIndex }">
                  <el-select
                    v-model="row.intakePort"
                    placeholder="请选择取水口"
                    :disabled="handleType == 'detail' || handleType == 'review'"
                    @change="getChnage(row, 'intakePort', rowIndex)"
                  >
                    <!-- <el-option label="总出水" value="总出水" />
                    <el-option label="总回水" value="总回水" />
                    <el-option label="总进水" value="总进水" />
                    <el-option label="总送水" value="总送水" />
                    <el-option label="男一更C1" value="男一更C1" />
                    <el-option label="女一更C2" value="女一更C2" />
                    <el-option label="女一更C3" value="女一更C3" />
                    <el-option label="洗衣间C4" value="洗衣间C4" />
                    <el-option label="洁具间C5" value="洁具间C5" />
                    <el-option label="清洗间C6" value="清洗间C6" />
                    <el-option label="实验室C7" value="实验室C7" />
                    <el-option label="实验室C8" value="实验室C8" />
                    <el-option label="实验室C9" value="实验室C9" /> -->
                    <el-option label="女一C1" value="女一C1" />
                    <el-option label="男一C2" value="男一C2" />
                    <el-option label="洗衣间C3" value="洗衣间C3" />
                    <el-option label="洗衣间C4" value="洗衣间C4" />
                    <el-option label="器具洗存间C5" value="器具洗存间C5" />
                    <el-option label="洁具间C6" value="洁具间C6" />
                    <el-option label="精洗烘干C7" value="精洗烘干C7" />
                    <el-option label="精洗烘干C8" value="精洗烘干C8" />
                    <el-option label="实验室C9" value="实验室C9" />
                    <el-option label="灭菌间C10" value="灭菌间C10" />
                    <el-option label="阳一更C11" value="阳一更C11" />
                    <el-option label="无菌一更C12" value="无菌一更C12" />
                    <el-option label="微一更C13" value="微一更C13" />
                    <el-option label="总送水C14" value="总送水C14" />
                    <el-option label="总回水C15" value="总回水C15" />
                    <el-option label="总出水C16" value="总出水C16" />
                    <el-option label="总进水C17" value="总进水C17" />
                  </el-select>
                </template>
              </vxe-table-column>
              <vxe-table-column field="checkItem" title="检测项目" />
              <vxe-table-column field="itemRequired" title="项目要求" />
              <vxe-table-column field="result" title="检测结果">
                <template #default="{ row, rowIndex }">
                  <el-radio-group
                    v-if="(rowIndex + 3) % 3 === 0"
                    v-model="row.checkResult"
                    :disabled="handleType == 'detail' || handleType == 'review'"
                    @change="getChnage(row, 'checkResult', rowIndex)"
                  >
                    <p>
                      <el-radio :label="1">
                        无色澄明，无臭无味
                      </el-radio>
                    </p>
                    <p>
                      <el-radio :label="2">
                        浑浊，有异臭味
                      </el-radio>
                    </p>
                  </el-radio-group>
                  <el-radio-group
                    v-if="(rowIndex + 2) % 3 === 0"
                    v-model="row.checkResult"
                    :disabled="handleType == 'detail' || handleType == 'review'"
                    @change="getChnage(row, 'checkResult', rowIndex)"
                  >
                    <el-radio :label="1">
                      不显红色
                    </el-radio>
                    <el-radio :label="2">
                      红色
                    </el-radio>
                  </el-radio-group>
                  <el-radio-group
                    v-if="(rowIndex + 1) % 3 === 0"
                    v-model="row.checkResult"
                    :disabled="handleType == 'detail' || handleType == 'review'"
                    @change="getChnage(row, 'checkResult', rowIndex)"
                  >
                    <el-radio :label="1">
                      不显蓝色
                    </el-radio>
                    <el-radio :label="2">
                      蓝色
                    </el-radio>
                  </el-radio-group>
                </template>
              </vxe-table-column>
              <vxe-table-column field="jielun" title="结论">
                <template #default="{ row, rowIndex }">
                  <el-radio-group
                    v-model="row.conclusion"
                    :disabled="handleType == 'detail' || handleType == 'review'"
                    @change="getChnage(row, 'conclusion', rowIndex)"
                  >
                    <el-radio label="1">
                      合格
                    </el-radio>
                    <el-radio label="2">
                      不合格
                    </el-radio>
                  </el-radio-group>
                </template>
              </vxe-table-column>
              <vxe-table-column
                v-if="handleType !== 'detail' && handleType !== 'review'"
                field="idx"
                title="操作"
                width="100"
                align="center"
              >
                <template #default="{ rowIndex }">
                  <el-button type="small" @click.stop="handleDelete2(rowIndex)">
                    删除
                  </el-button>
                </template>
              </vxe-table-column>
            </vxe-table>
          </section>
          <el-row>
            <el-row
              v-if="handleType !== 'add' && handleType !== 'update'"
              class="newRule"
            >
              <el-col :span="12">
                <el-form-item label="复核结果" prop="reviewerResult">
                  <el-radio-group
                    v-model="waterDailyMonitorRecordForm.reviewerResult"
                    :disabled="handleType !== 'review'"
                  >
                    <el-radio :label="1">
                      通过
                    </el-radio>
                    <el-radio :label="2">
                      不通过
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-row>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="waterDailyMonitorRecordDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="waterDailyMonitorRecordFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="waterDailyMonitorRecordFormTitle === '纯化水常规监控记录详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="检测日期">
        <el-date-picker
          v-model="searchForm.checkDate"
          placeholder="请选择日期"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="waterDailyMonitorRecordPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="checker" label="检测人" />
      <el-table-column prop="checkerDate" label="检测日期" />
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column prop="reviewerDate" label="复核日期" />
      <el-table-column prop="reviewerResult" label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerResult == 1">通过</span>
          <span v-if="scope.row.reviewerResult == 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="waterDailyMonitorRecordPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addWaterDailyRecord,
  deleteWaterDailyRecord,
  updateWaterDailyRecord,
  reviewWaterDailyRecord,
  selectWaterDailyRecordInfo,
  selectWaterDailyRecordList
} from '@/api/quality/waterDailyMonitorRecord'
import html2PDF from 'jspdf-html2canvas'
import { Loading } from 'element-ui'

export default {
  data () {
    return {
      waterDailyMonitorRecordDialogVisible: false,
      waterDailyMonitorRecordFormTitle: '',
      waterDailyMonitorRecordForm: {
        reviewer: '',
        reviewDate: '',
        checkDate: '',
        detailJson: ''
      },
      waterDailyMonitorRecordFormRules: {
        checkDate: [
          {
            required: true,
            message: '检测时间不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      waterDailyMonitorRecordPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        checkDate: ''
      },
      tableDataDemo: {
        idx: '00',
        intakePort: '',
        checkItem: '',
        checkResult: '',
        conclusion: '',
        itemRequired: ''
      },
      tableData: [],
      handleType: ''
    }
  },
  watch: {
    waterDailyMonitorRecordDialogVisible (val) {
      if (!val) {
        this.waterDailyMonitorRecordForm = {}
        this.handleType = ''
        this.tableData = []
      }
    }
  },
  created () {
    selectWaterDailyRecordList(this.searchForm).then((res) => {
      this.waterDailyMonitorRecordPage = res
    })
  },
  methods: {
    waterDailyMonitorRecordDialogClose () {
      this.$refs.waterDailyMonitorRecordFormRef.resetFields()
      this.waterDailyMonitorRecordForm.id = ''
    },
    waterDailyMonitorRecordFormSubmit () {
      if (this.waterDailyMonitorRecordFormTitle === '纯化水常规监控记录') {
        this.waterDailyMonitorRecordDialogVisible = false
        return
      }
      this.$refs.waterDailyMonitorRecordFormRef.validate(async (valid) => {
        if (valid) {
          let tempArr = this.tableData
          if (!tempArr.length) {
            this.$message({
              type: 'warning',
              message: '必须有监控记录数据'
            })
            console.log(tempArr, 'tempArr')
            return
          }
          for (let i in tempArr) {
            if (
              !tempArr[i].checkResult
              || !tempArr[i].conclusion
              || !tempArr[i].intakePort
            ) {
              this.$message({
                type: 'warning',
                message: '监控数据输入项不能为空'
              })
              return
            }
          }
          this.waterDailyMonitorRecordForm.detailJson = JSON.stringify(tempArr)
          if (this.handleType === 'add') {
            await addWaterDailyRecord(this.waterDailyMonitorRecordForm)
          } else if (this.handleType === 'update') {
            await updateWaterDailyRecord(this.waterDailyMonitorRecordForm)
          } else if (this.handleType === 'review') {
            await reviewWaterDailyRecord(this.waterDailyMonitorRecordForm)
          }
          this.waterDailyMonitorRecordPage = await selectWaterDailyRecordList(this.searchForm)
          this.waterDailyMonitorRecordDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.waterDailyMonitorRecordFormTitle = '新增纯化水常规监控记录'
      this.waterDailyMonitorRecordDialogVisible = true
      this.handleType = 'add'
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteWaterDailyRecord(row.id)
        if (
          this.waterDailyMonitorRecordPage.list.length === 1
          && this.searchForm.pageNum > 1
        ) {
          this.searchForm.pageNum--
        }
        this.waterDailyMonitorRecordPage = await selectWaterDailyRecordList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.handleType = 'update'
      this.waterDailyMonitorRecordFormTitle = '修改纯化水常规监控记录'
      this.waterDailyMonitorRecordDialogVisible = true
      this.selectWaterDailyMonitorRecordById(row.id)
    },
    handleReview (index, row) {
      this.handleType = 'review'
      this.waterDailyMonitorRecordFormTitle = '复核纯化水常规监控记录'
      this.waterDailyMonitorRecordDialogVisible = true
      this.selectWaterDailyMonitorRecordById(row.id)
    },
    handleInfo (index, row) {
      this.handleType = 'detail'
      this.waterDailyMonitorRecordFormTitle = '纯化水常规监控记录详情'
      this.waterDailyMonitorRecordDialogVisible = true
      this.selectWaterDailyMonitorRecordById(row.id)
    },
    selectWaterDailyMonitorRecordById (id) {
      selectWaterDailyRecordInfo(id).then((res) => {
        console.log(res, 'res')
        this.waterDailyMonitorRecordForm = res
        this.tableData = res.detailList.map((item, index) => {
          let length = parseInt(index / 3)
          item.idx = '0' + length
          if ((index + 3) % 3 === 0) {
            item.jielun = 1
          } else {
            item.jielun = 2
          }
          return item
        })
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectWaterDailyRecordList(this.searchForm).then((res) => {
        this.waterDailyMonitorRecordPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectWaterDailyRecordList(this.searchForm).then((res) => {
        this.waterDailyMonitorRecordPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectWaterDailyRecordList(this.searchForm).then((res) => {
        this.waterDailyMonitorRecordPage = res
      })
    },
    // 通用行合并函数（将相同多列数据合并为一行）
    rowspanMethod ({ row, _rowIndex, column, visibleData }) {
      let fields = ['idx', 'checkItem', 'checkRequire', 'result', 'jielun']
      let cellValue = row[column.property]
      if (cellValue && fields.includes(column.property)) {
        let prevRow = visibleData[_rowIndex - 1]
        let nextRow = visibleData[_rowIndex + 1]
        if (prevRow && prevRow[column.property] === cellValue) {
          return { rowspan: 0, colspan: 0 }
        } else {
          let countRowspan = 1
          while (nextRow && nextRow[column.property] === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return { rowspan: countRowspan, colspan: 1 }
          }
        }
      }
    },
    addList () {
      let tableData = JSON.parse(JSON.stringify(this.tableData))
      let tableDataDemo = JSON.parse(JSON.stringify(this.tableDataDemo))
      let length = tableData.length
      let tempArr = []
      tempArr = [
        JSON.parse(JSON.stringify(tableDataDemo)),
        JSON.parse(JSON.stringify(tableDataDemo)),
        JSON.parse(JSON.stringify(tableDataDemo))
      ]
      for (let i in tempArr) {
        if (i == 0) {
          tempArr[i].idx = '0' + length
          tempArr[i].checkItem = '性状'
          tempArr[i].itemRequired = '无色澄明，无臭无味'
          tempArr[i].jielun = 1
          tempArr[i].result = 1
        }
        if (i == 1 || i == 2) {
          tempArr[i].checkItem = '酸碱度'
          tempArr[i].jielun = 2
        }
        if (i == 1) {
          tempArr[i].idx = '0' + length
          tempArr[i].itemRequired = '加两滴，不显红色'
          tempArr[i].result = 2
        }
        if (i == 2) {
          tempArr[i].idx = '0' + length
          tempArr[i].itemRequired = '加五滴，不显蓝色'
          tempArr[i].result = 3
        }
      }
      tableData = tableData.concat(...tempArr)
      this.tableData = tableData
    },
    getList () {
      let tableData = JSON.parse(JSON.stringify(this.tableData))
      console.log(tableData, 'tableData')
    },
    getChnage (row, type, rowIndex) {
      this.tableData[rowIndex][type] = row[type]
      if (type === 'intakePort') {
        this.tableData[rowIndex + 1][type] = row[type]
        this.tableData[rowIndex + 2][type] = row[type]
      }
      if (type === 'conclusion' && (rowIndex + 2) % 3 === 0) {
        this.tableData[rowIndex + 1][type] = row[type]
      }
    },
    handleDelete2 (index) {
      let _this = this
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        console.log(index, 'index')
        let tableData = JSON.parse(JSON.stringify(_this.tableData))
        tableData.splice(index, 3)
        _this.tableData = tableData
      })
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `纯化水常规监控记录${this.waterDailyMonitorRecordForm.checkDate.substring(0, 10)}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style lang='css'>
.newRule {
  /* margin-bottom: 20px; */
}
.newRule .el-input.is-disabled .el-input__inner {
  background-color: #fff;
  border-color: #dcdfe6;
  color: #606266;
}
.newRule .el-radio__input.is-disabled.is-checked + span.el-radio__label {
  color: #409eff;
}
.newRule .el-radio__input.is-disabled.is-checked .el-radio__inner {
  border-color: #409eff;
  background: #409eff;
}
.newRule .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
  background-color: #f5f7fa;
}
.el-form {
  padding-left: 20px;
}
</style>
